footer {
    position: fixed;
    bottom: 0;
    padding: 0 1%;
    width: 98%;
    font-size: small;
    background-color: #fffc;
    border-top: 1px solid white;
}
footer p { display: inline-block; }
footer nav { float: right; }
footer nav ul { display: inline-flex; list-style: none; }
footer nav ul li {}
footer nav ul li.separator { margin: 0 1vmin; }
footer nav ul li a { color: #0066ff; text-decoration: none; }
footer nav ul li a:hover { color: #0033cc; text-decoration: underline; }
