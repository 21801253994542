header { position: fixed; top: 0; z-index: 10000; width: 100%; height: 64px; background-color: #fffc; font-family: "Squada One", cursive; border-bottom: 1px solid white; }

header a.logo { display:block; float: left; }
header a.logo img[alt="logo"] { height: 64px; }

.navigation.mobile { display: none; }

@media (max-width: 768px) {
    .navigation.desktop { display: none; }
    .navigation.mobile { display: flex; align-items: center; }
}

header nav {}
.navigation.desktop .main {display: inline; margin: 0; margin-right: 32px; padding: 0; float: right; list-style: none; }
.navigation.desktop .main > .menuitem { display: inline-block; margin: 0; padding: 0; font-size: 1.2em; }
.navigation.desktop .main > .menuitem a { display: inline-block; padding: 21px 16px; color: #0066ff; font-weight: normal; text-decoration: none; }
.navigation.desktop .main > .menuitem a:hover { color: #0033cc; }
.navigation.desktop .main > .menuitem.separator {}

.navigation.desktop .popup { position: relative; }
.navigation.desktop .popup ul {
    z-index: 10000;
    position: absolute;
    padding: 16px;
    top: 0;
    right: 0;
    background-color: #ffffffee;
    border-radius: 4px;
    border: 1px solid #ffffff;
}
.navigation.desktop .popup ul > li { display: block; width: 160px; }
.navigation.desktop .popup ul > li.separator { margin: 16px 0; border-top: 1px dotted #0066ff; }

.navigation.desktop .main > .menuitem > .popup li { font-size: 0.8em; }
.navigation.desktop .main > .menuitem > .popup a { display: block; padding: 0; font-weight: normal; text-decoration: none; }
.navigation.desktop .main > .menuitem > .popup .icon {
    display: inline-block;
    margin: 8px;
    width: 32px; height: 32px;
    vertical-align: middle;
    background-position: center;
    background-size: contain;
}
.popup .icon.icon-instagram {
    background-image: url('/public/icons/instagram.png');
}
.popup .icon.icon-youtube {
    background-image: url('/public/icons/youtube.png');
}

.navigation.mobile {}
.navigation.mobile .toggle { position: absolute; right: 2%; margin-top: 64px; cursor: pointer; }

.navigation.mobile .main {
    z-index: 10000;
    display: flex;
    overflow: scroll;
    position: absolute;
    top: 48px;
    right: 0;
    width: 96%;
    height: 50vh;
    flex-direction: column;
    justify-content: space-evenly;
    list-style: none;
    background-color: #fffe;
}

.navigation.mobile .popup { list-style: none; margin: 0; padding: 0; }

.navigation.mobile .popup ul > li.separator { margin: 4px 0; border-top: 1px dotted #0066ff; }
.navigation.mobile .main > .menuitem > .popup .icon {
    display: inline-block;
    margin: 8px;
    width: 16px; height: 16px;
    vertical-align: middle;
    background-position: center;
    background-size: contain;
}
