body {
  margin: 0;
  font-family: -apple-system, 'SUSE', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #245E55;
}

a { color: #0066ff; text-decoration: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#bg-wrapper {
  position: relative;
  height: 100%;
}

#bg-wrapper:before {
  z-index: -1;
  content: ' ';
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-blend-mode: color-burn;
  background: url('background.jpg') no-repeat center center fixed;
  opacity: .8;
}

.clear { clear: both; }
.center { text-align: center; }
.smaller { font-size: smaller; }
.scroller { height: 86vh; overflow: scroll; }

.box {
  margin: 0 18px 18px 18px;
  padding: 0 16px;
  background-color: #fff9;
  border: 1px solid white;
  border-radius: 6px;
}

h1, h2, h3, h4, h5, h6 { font-family: "Squada One", cursive; }
h1 { font-size: 1.4em; }
h2 { font-size: 1.3em; }
h3 { font-size: 1.2em; }
h4 { font-size: 1.1em; }

hgroup h1 { margin-bottom: 8px; }
hgroup h2 { margin-top: 0; }

hgroup h3 { margin-bottom: 4px; }
hgroup h4 { margin: 0; }

sup { vertical-align: top; }

form { margin-bottom: 16px; }

input, select, textarea {
  padding: 4%;
  width: 92%;
  font-family: inherit;
  font-size: inherit;
  color: #0033cc;
  background-color: #fff9;
  border: 1px solid white;
  border-radius: 4px;
}

select { width: 100%; padding: 2%; }
textarea { width: 96%; padding: 2%; resize: vertical; }

button, input[type=submit], input[type=reset], .button {
  width: 100%;
  font-family: "Squada One", cursive;
  color: white;
  cursor: pointer;
  background-color: #0066ff;
}

button:disabled, input[type=submit]:disabled, input[type=reset]:disabled, .button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

a.button { display: inline-block; margin: 16px 0; padding: 2%; width: 88%; text-decoration: none; border-radius: 6px; }
a.button:hover { background-color: #0033cc; }

input[type=reset] { color: #0033cc; background-color: #ccc; }

.listing { line-height: 1.5; }
.listing li { margin: 1% 0; }

ul.feed { margin: 8px 0; padding: 0; list-style: none; font-size: 11pt; line-height: 1; }
li.feed-item { margin-bottom: 16px; padding: 8px; background-color: #fff9; border: 1px solid white; border-radius: 4px; }